<template>
    <div class="content">
        <div class="top">
            <div class="time-line">
                <span>8:00</span>
                <span class="time-line-show">{{showTime}}</span>
                <span>16:00</span>
            </div>
            <div class="season-list">
                <span class="season-item" :class="{'season-item-on':item.value === selectedSeasonValue}" v-for="item in seasonList" @click="handleSeasonItem(item)">{{item.name}}</span>
            </div>
            <div class="top-tip">当前位置为北纬33°N</div>
        </div>
        <div id="three"></div>
    </div>
</template>
<script>
import * as THREE from 'three'
import { BoxLineGeometry } from 'three/examples/jsm/geometries/BoxLineGeometry.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
// import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import { CSM } from 'three/examples/jsm/csm/CSM.js';
import { CSMHelper } from 'three/examples/jsm/csm/CSMHelper.js';
// import { createMultiMaterialObject } from 'three/addons/utils/SceneUtils.js';
export default {
    name: "threeManage",
    data() {
        return {
            id: '',
            buildingList: [],
            showTime: '8:00',
            seasonList: [
                {name: '春分', value: 0, angle: 0, ratio: 0.75},
                {name: '夏至', value: 1, angle: 23.26, ratio: 0.375},
                {name: '秋分', value: 2, angle: 0, ratio: 0.75},
                {name: '冬至', value: 3, angle: 23.26, ratio: 1.5},
            ],
            selectedSeasonValue: 0,
            solarAltitudeAngle: 50,
            ratio: 0.75,
            timeInterval: null,

        }
    },
    components: {

    },
    created() {
        this.id = this.$route.query.id

        // console.log(this.$route.query.id)

        // this.getList()
        // if (!!localStorage.user && !!localStorage.password){
        //     this.params.user = localStorage.user
        //     this.params.password = localStorage.password
        // }

        document.title = '日照'
    },
    mounted() {

        setTimeout(()=>{
            this.init()
        },333)

    },
    methods: {
        async init() {
            await this.getList()
            this.setThree()
        },
        handleSeasonItem(data) {
            this.selectedSeasonValue = data.value
            this.ratio = data.ratio
            // this.solarAltitudeAngle = (90 - (40 - data.angle))
            // console.log('solarAltitudeAngle', this.solarAltitudeAngle)
            let el = document.getElementById('three')
            let childs = el.childNodes
            for(let i = childs.length - 1; i >= 0; i--) {
                el.removeChild(childs[i])
            }
            if (!!this.timeInterval) {
                clearInterval(this.timeInterval)
                this.timeInterval = null
            }
            this.setThree()
        },
        setThree() {
            const buildingList = this.buildingList
            let renderer, scene, camera, orthoCamera, controls, csm, csmHelper
            /**
             * 计算太阳光照方向
             *
             *  太阳高度，指太阳光的入射方向和地平面之间的夹角，计算公式h=90°-|φ-δ|。|φ-δ|，即本地纬度与太阳直接点纬度之差的绝对值，同纬相减，异纬相加。今日太阳直射南回归线23.5°S，青岛本地纬度约为36°N，今日正午太阳高度即为90°-（36°+23.5°）=30.5°。*/
            let timeOfDay = 8


            let theta = 2 * Math.PI * (timeOfDay - 12) / 24 // 时间（0-24小时）

            let ratio = this.ratio
            let latitude = 33
            let phi = Math.PI * (90 - latitude) / 180 // 纬度（-90到90度）
            console.log(phi)
            let sunAngle = Math.sin(phi)

            console.log('sunAngle', sunAngle)
            // let phi = -(Math.PI * 20 / 180) // 纬度（-90到90度）
            // let phi = this.solarAltitudeAngle

            let timeSum = -(Math.cos(phi) * Math.sin(theta))
            let sunDirection = new THREE.Vector3(
                Math.cos(phi) * Math.cos(theta),
                Math.sin(phi),
                Math.cos(phi) * Math.sin(theta)
            )
            let hour = 8
            let minute = 0
            let showTime = '8:00'
            // console.log('时间', timeSum)
            // 8点 0.47167124021565593
            // 9点 0.3851179549580232
            // 16点 -0.47167124021565593

            // 0.47167124021565593 +12

            // 0.08655328525763273 / 12 === 0.007212773771469394

            // console.log(Math.cos(phi) * Math.cos(theta), Math.sin(phi), Math.cos(phi) * Math.sin(theta))
            // console.log('timeSum',theta,phi,sunDirection)



            this.timeInterval = setInterval(()=>{

                timeOfDay = Math.round((timeOfDay + 0.1) * 100 ) /100
                if (timeOfDay === 16) {
                    timeOfDay = 8
                }
                theta = 2 * Math.PI * (timeOfDay - 12) / 24 // 时间（0-24小时）
                timeSum = -(Math.cos(phi) * Math.sin(theta))
                // console.log(timeOfDay, timeSum)
                csm.lightDirection.z = timeSum
                // csm.lightDirection.y
                // console.log('Math.cos(phi) * Math.cos(theta)',Math.cos(phi) * Math.cos(theta))
                // console.log(Math.sin(phi))
                csm.lightDirection.x = -(Math.cos(phi) * Math.cos(theta) * ratio)
                csm.lightDirection.y = -(Math.cos(phi) * Math.cos(theta))

                // csm.lightDirection.z = csm.lightDirection.z - 0.007212773771469394
                //
                // if (csm.lightDirection.z <= -0.47167124021565593) {
                //     csm.lightDirection.z = 0.47167124021565593
                // }
                minute = minute + 6
                if (minute === 60) {
                    hour++
                    if (hour === 16) {
                        hour = 8
                    }
                    minute = 0
                }

                //
                //
                showTime = `${hour}:${minute === 0?'00':minute === 6?'06':minute}`
                this.showTime = showTime
                // console.log(hour,minute)
            },100)

            // // 设置太阳光照方向
            // sun.position.copy(sunDirection);
            // sun.lookAt(new THREE.Vector3(0, 0, 0)); // 光源指向场景中心

            const params = {
                orthographic: false,
                fade: false,
                far: 1000,
                mode: 'practical',
                // lightX: -10,
                // lightY: -1,
                // lightZ: 1,
                lightX: -Math.cos(phi) * Math.cos(theta) * ratio,
                // lightX: sunAngle,

                lightY: -Math.cos(phi) * Math.cos(theta),

                // lightY: -10,

                lightZ: timeSum,
                margin: 100,
                lightFar: 5000,
                lightNear: 1,
                autoUpdateHelper: true,
                updateHelper: function () {
                    csmHelper.update()
                }
            }
            init()
            animate()
            function updateOrthoCamera() {

                const size = controls.target.distanceTo( camera.position );
                const aspect = camera.aspect;

                orthoCamera.left = size * aspect / - 2;
                orthoCamera.right = size * aspect / 2;

                orthoCamera.top = size / 2;
                orthoCamera.bottom = size / - 2;
                orthoCamera.position.copy( camera.position );
                orthoCamera.rotation.copy( camera.rotation );
                orthoCamera.updateProjectionMatrix();

            }

            function init() {

                scene = new THREE.Scene();
                scene.background = new THREE.Color( '#7eaef9' );
                camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.1, 5000 );
                orthoCamera = new THREE.OrthographicCamera();

                renderer = new THREE.WebGLRenderer( { antialias: true } );
                renderer.setSize( window.innerWidth, window.innerHeight );
                // console.log(document.getElementById('three'))
                document.getElementById('three').appendChild( renderer.domElement );
                renderer.shadowMap.enabled = true;
                renderer.shadowMap.type = THREE.PCFSoftShadowMap;

                controls = new OrbitControls( camera, renderer.domElement,8,8,8 );
                controls.maxPolarAngle = Math.PI / 2;
                camera.position.set( 60, 60, 10 );
                controls.target = new THREE.Vector3( - 100, 10, 0 );
                controls.update();

                /**
                 * 光的颜色和亮度*/
                const ambientLight = new THREE.AmbientLight( '#ffffff', 0.9 );
                scene.add( ambientLight );

                // const additionalDirectionalLight = new THREE.DirectionalLight( 0x000020, 1 );
                // Math.cos(phi) * Math.cos(theta),
                //     Math.sin(phi),
                // Math.cos(phi) * Math.sin(theta)
                // additionalDirectionalLight.position.set( Math.cos(phi) * Math.cos(theta), Math.sin(phi), Math.cos(phi) * Math.sin(theta) ).normalize().multiplyScalar( - 200 );
                // additionalDirectionalLight.position.set( params.lightX, params.lightY, params.lightZ ).normalize().multiplyScalar( - 200 );
                // additionalDirectionalLight.position.copy(sunDirection);
                // additionalDirectionalLight.lookAt(new THREE.Vector3(0, 0, 0)); // 光源指向场景中心

                // scene.add( additionalDirectionalLight );

                // const material = new THREE.LineBasicMaterial( { color: 0x0000ff } );
                // const points = [];
                // points.push( new THREE.Vector3( 10, 0, 0 ) );
                // points.push( new THREE.Vector3( 0, 10, 0 ) );
                // points.push( new THREE.Vector3( 10, 0, 10 ) );
                //
                // const gg = new THREE.BufferGeometry().setFromPoints( points );
                // const line = new THREE.Line( gg, material );
                // scene.add( line );




                csm = new CSM( {
                    maxFar: params.far,
                    cascades: 0,
                    mode: params.mode,
                    parent: scene,
                    shadowMapSize: 2048,
                    lightDirection: new THREE.Vector3( params.lightX, params.lightY, params.lightZ ).normalize(),
                    camera: camera
                })

                csmHelper = new CSMHelper( csm );
                csmHelper.visible = false;
                scene.add( csmHelper );

                /**
                 * 添加地面*/
                const floorMaterial = new THREE.MeshPhongMaterial( { color: '#d3dce3' } );
                csm.setupMaterial( floorMaterial );
                const floor = new THREE.Mesh( new THREE.PlaneGeometry( 1500, 1500), floorMaterial );
                floor.rotation.x = - Math.PI / 2;
                floor.castShadow = true;
                floor.receiveShadow = true;
                scene.add( floor );






                // const material0 = new THREE.MeshPhongMaterial( { color: '#ee7f6d' } );
                // csm.setupMaterial( material0 );
                // const material1 = new THREE.MeshPhongMaterial( { color: '#00ffd1' } );
                // csm.setupMaterial( material1 );
                // const material2 = new THREE.MeshPhongMaterial( { color: '#ec4f39' } );
                // csm.setupMaterial( material2 );
                // const material3 = new THREE.MeshPhongMaterial( { color: '#bf00ff' } );
                // csm.setupMaterial( material3 );


                // scene.background = new THREE.Color( '#ff0000' );
                // let room = new THREE.LineSegments(
                //     new BoxLineGeometry( 1, 32, 4, 1, 64, 4 ),
                //     new THREE.LineBasicMaterial( { color: '#ffffff' } )
                // )
                //
                // room.castShadow = true
                // room.receiveShadow = true
                // // room.geometry.translate( 0, 0, 0 );
                // room.position.set( -32, 0, 16 )
                //
                // scene.add( room )


                /**
                 * (a,b,c)
                 * a是 深度
                 * b是高度
                 * c宽度*/
                    // const one = new THREE.BoxGeometry( 4, 32, 4);
                    // const tt = new THREE.Mesh( one, material99);
                    // tt.castShadow = true;
                    // tt.receiveShadow = true;
                    // scene.add( tt );
                    // tt.position.set( 0, 0, 0 );
                    // tt.scale.y = 8;

                    // const one2 = new THREE.BoxGeometry( 4, 0.25, 4);


                    // for (let i = 0; i < 3; i++) {
                    //     // let aaa = 'material0'
                    //     const material90 = new THREE.MeshPhongMaterial( { color: '#ee7f6d' } );
                    //     csm.setupMaterial( material90 );
                    //     const ttt = new THREE.Mesh( one, material90);
                    //     ttt.castShadow = true;
                    //     ttt.receiveShadow = true;
                    //     scene.add( ttt );
                    //     ttt.position.set( 0, 0, -4 * i);
                    //     // ttt.scale.y = -Math.PI / 2;
                    // }
                    // for (let i = 0; i < 3; i++) {
                    //     // let aaa = 'material0'
                    //     let _material = new THREE.MeshPhongMaterial( { color: '#ff2500' } );
                    //     csm.setupMaterial( _material );
                    //     const ttt = new THREE.Mesh( one, _material);
                    //     ttt.castShadow = true;
                    //     ttt.receiveShadow = true;
                    //     scene.add( ttt );
                    //     ttt.position.set( 0, 2, -4 * i);
                    //     // ttt.scale.y = 8;
                    // }
                    // for (let i = 0; i < 3; i++) {
                    //     // let aaa = 'material0'
                    //     let _material = new THREE.MeshPhongMaterial( { color: '#0065ff' } );
                    //     csm.setupMaterial( _material );
                    //     const ttt = new THREE.Mesh( one, _material);
                    //     ttt.castShadow = true;
                    //     ttt.receiveShadow = true;
                    //     scene.add( ttt );
                    //     ttt.position.set( 0, 4, -4 * i);
                    //     // ttt.scale.y = 8;
                    // }

                // const geometry = new THREE.BoxGeometry( 1, 32, 4);
                // // 创建材质
                // // const meshMaterial = new THREE.MeshNormalMaterial({
                // //     side: THREE.DoubleSide
                // // })
                // // const wireFrameMat = new THREE.MeshBasicMaterial({ wireframe: true })
                //
                // // // 添加组合材质
                // // let this_mesh = createMultiMaterialObject(geometry, [
                // //     meshMaterial,
                // //     // wireFrameMat
                // // ])
                // //
                // // // 网格对象添加到场景中
                // // scene.add(this_mesh)
                //
                //
                // // const cube99 = new THREE.Mesh( geometry, material99);
                // // cube99.castShadow = true;
                // // cube99.receiveShadow = true;
                // // scene.add( cube99 );
                // // cube99.position.set( 0, 0, 0 );
                // // cube99.scale.y = 8;
                //
                // const cube98 = new THREE.Mesh( geometry, material0);
                // // 添加纹理
                // // var wallTexture = new THREE.TextureLoader().load('poster.jpg');
                // // var wallMaterial = new THREE.MeshBasicMaterial({ map: wallTexture });
                // // cube98.material = wallMaterial;
                //
                //
                //
                // cube98.castShadow = true;
                // cube98.receiveShadow = true;
                // scene.add( cube98 );
                // /**
                //  * (a,b,c)
                //  * a是深度
                //  * b是高度
                //  * c x轴*/
                // cube98.position.set( -32, 0, 16 )
                // cube98.scale.y = 8;

                buildingList.forEach(item=>{
                    console.log(item)

                    let _xyz = item.xyz.split(',')
                    let _geometry = new THREE.BoxGeometry( 1, item.h, item.w)
                    let _material = new THREE.MeshPhongMaterial( { color: '#ee7f6d' })
                    csm.setupMaterial(_material)
                    let _cube = new THREE.Mesh(_geometry, _material)
                    _cube.castShadow = true
                    _cube.receiveShadow = true
                    _cube.position.set(_xyz[1], 0, _xyz[0])
                    scene.add(_cube)



                    let _line = new THREE.LineSegments(
                        new BoxLineGeometry( 1, item.h, item.w, 1, item.h * 2, item.w ),
                        new THREE.LineBasicMaterial( { color: '#ffffff' } )
                    )
                    _line.castShadow = true
                    _line.receiveShadow = true
                    _line.position.set(_xyz[1], 0, _xyz[0])
                    scene.add(_line)

                })



                // //立方体
                // var cubeGeometry = new THREE.BoxGeometry(4,64,16,1,32,3);
                // var cubeMaterial = new THREE.MeshNormalMaterial({
                //     wireframe : true,
                //     color : '#fff',
                //     opacity : 0.2
                // });  //材质
                //
                // let bb = new THREE.Mesh(cubeGeometry, cubeMaterial);
                // bb.position.set( 0, 0, 0 );
                //
                // // var border = new THREE.EdgesHelper( bb,0xffff00 );  //添加边框
                // scene.add(bb);
                // // scene.add(border);


                //
                // for ( let i = 0; i < 20; i ++ ) {
                //
                //     const cube1 = new THREE.Mesh( geometry, i % 2 === 0 ? material1 : material2 );
                //     cube1.castShadow = true;
                //     cube1.receiveShadow = true;
                //     scene.add( cube1 );
                //     cube1.position.set( - i * 25, 20, 30 );
                //     cube1.scale.y = Math.random() * 2 + 6;
                //
                //     const cube2 = new THREE.Mesh( geometry, i % 2 === 0 ? material2 : material1 );
                //     cube2.castShadow = true;
                //     cube2.receiveShadow = true;
                //     scene.add( cube2 );
                //     cube2.position.set( - i * 25, 20, - 30 );
                //     cube2.scale.y = Math.random() * 2 + 6;
                //
                //     const cube3 = new THREE.Mesh( geometry, i % 2 === 0 ? material2 : material1 );
                //     cube3.castSxhadow = true;
                //     cube3.receiveShadow = true;
                //     scene.add( cube3 );
                //     cube3.position.set( - i * 25, 20, - 30 );
                //     cube3.scale.y = Math.random() * 2 + 6;
                //
                // }


                window.addEventListener( 'resize', function () {
                    camera.aspect = window.innerWidth / window.innerHeight
                    camera.updateProjectionMatrix()
                    updateOrthoCamera()
                    csm.updateFrustums()
                    renderer.setSize( window.innerWidth, window.innerHeight )
                })
            }

            function animate() {

                requestAnimationFrame( animate );

                camera.updateMatrixWorld();
                csm.update();
                controls.update();

                if ( params.orthographic ) {

                    updateOrthoCamera();
                    csm.updateFrustums();

                    if ( params.autoUpdateHelper ) {

                        csmHelper.update();

                    }

                    renderer.render( scene, orthoCamera );

                } else {

                    if ( params.autoUpdateHelper ) {

                        csmHelper.update();

                    }

                    renderer.render( scene, camera );

                }

            }


        },
        async getList() {
            let params = {
                id: this.id,
            }
            // https://muyu.spjgzs.com/api/jh/muyu/record
            // this.projectList = []
            let res = await this.$util.ajax('/jh/muyu/searchThree', 'post' ,params)
            // console.log(res)
            if (!!res && !!res.data) {
                document.title = res.data.name + '-日照'
                this.buildingList = JSON.parse(res.data.building_list)
            //     res.data.forEach(item=>{
            //         item.name = item.name || '无项目名称'
            //         item.buildingList = !!item.buildingList?JSON.parse(item.buildingList):[]
            //         this.projectList.push(item)
            //     })
            }
        },
    }
}
</script>

<style scoped lang="less">
.content {
    width: 100%;
    height: 100%;
    position: relative;
    .top {
        width: 360px;
        background: #fff;
        position: absolute;
        left: calc(50% - 180px);
        top: 32px;
        box-sizing: border-box;
        padding: 16px;
        border-radius: 2px;
        .time-line {
            display: flex;
            align-items: center;
            font-size: 12px;
            .time-line-show {
                width: 0;
                flex: 1;
                text-align: center;
                font-size: 18px;
                font-weight: bold;
            }
        }
        .season-list {
            width: 100%;
            display: flex;
            align-items: center;
            padding-top: 32px;
            .season-item {
                width: 70px;
                height: 36px;
                line-height: 36px;
                text-align: center;
                font-size: 12px;
                color: #666;
                background: #ececec;
                margin-right: 16px;
                border-radius: 2px;
                &:last-child {
                    margin-right: 0;
                }
            }
            .season-item-on {
                background: rgba(41, 83, 255, 0.18);
            }
        }
        .top-tip {
            font-size: 10px;
            color: #c4c4c4;
            padding-top: 16px;
            text-align: center;
        }
    }
}
</style>
